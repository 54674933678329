import axios from 'axios';
import router from '@/routers';
const actions = {
    login({ commit }, userData){
        commit('authUser', userData);
        axios.defaults.headers.common['Authorization'] = `Bearer ${userData.key.token}`;
        router.push('/');
    },
    logout({ commit }){
        delete axios.defaults.headers.common['Authorization'];
        commit('setNav', [])
        commit('logoutUser');
    },
    setUser({ commit }, user){
        commit('setUser', user);
    },
    setNav({ commit }, nav){
        commit('setNav', nav);
    },
}

export default actions;