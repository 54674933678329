let mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setNav(state, nav) {
        state.nav = nav;
    },
    authUser(state, user) {
        state.user = user.user;
        localStorage.setItem('token', user.key.token);
        localStorage.setItem('uid', user.id);
    },
    logoutUser(state) {
        state.user = {};
        localStorage.removeItem('token');
        localStorage.removeItem('uid');
    },
    set_spinner(state,boolean){
        state.spinner = boolean;
    }
}
export default mutations;
