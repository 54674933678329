// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import VeeValidate from 'vee-validate'
import router from './routers'
import axios from 'axios'
import store from './store/store.js'

//date time
import moment from 'moment';

//dialog
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

//currency
import VueCurrencyFilter from 'vue-currency-filter'
import money from 'v-money'


import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)


// todo
// cssVars()

Vue.mixin({
    methods: {
        $showToast:function(title, message, variant = 'success') {
            const _ = this
            _.$root.$bvToast.toast(message, {
              title,
              variant,
              solid: true,
              autoHideDelay: 3000,
            })
          }
    },
  })

Vue.use(BootstrapVue)
Vue.use(VuejsDialog, {
    html: true,
    okText: 'OK',
    cancelText: 'Cancel',
})
Vue.use(VeeValidate)

Vue.use(VueCurrencyFilter, {
    symbol: '',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
})
Vue.use(money, { precision: 0, thousands: '.' })

Vue.filter('formatDate', function(value, format = 'DD MMMM YYYY') {
    if (value) {
        return moment(String(value)).format(format)
    }
})


// axios setting
Vue.prototype.$axios = axios
// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.VUE_APP_API
axios.defaults.headers.get['Content-Type'] = 'application/json'

if (localStorage.getItem('token')) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
}

axios.interceptors.request.use(function(config) {
    store.commit('set_spinner', true)
    return config;
}, function(error) {
    return Promise.reject(error);
});

// 401 and 422 handle
axios.interceptors.response.use(function(response) {
    store.commit('set_spinner', false);
    return response;
}, function(error) {
    store.commit('set_spinner', false);
    if (error.response.status === 401) {
        store.dispatch('logout');
        router.push('/login');
    }
    if (error.response.status === 500) {
        router.push('/500');
    }
    return Promise.reject(error);
})

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    template: '<App/>',
    components: {
        App
    }
})