// category
const Listcategory = () => import('@/views/news_management/category/index')
const Formcategory = () => import('@/views/news_management/category/form')

export default {
    path: 'category',
    redirect: '/category',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'Category'
    },
    children: [
        {
            path: '/',
            name: 'category',
            component: Listcategory,
            meta: {
                auth: true,
                label: 'News Category',
                permission: {
                    link: "category",
                    name: "News Category",
                }
            }
        },
        {
            path: 'add',
            name: 'addcategory',
            component: Formcategory,
            meta: {
                auth: true,
                label: 'Add News Category',
                permission: {
                    link: "category",
                    name: "News Category"
                }
            }
        },
        {
            path: 'edit/:id',
            name: 'editcategory',
            component: Formcategory,
            meta: {
                auth: true,
                label: 'Edit News Category',
                permission: {
                    link: "category",
                    name: "News Category"
                },
            }
        },
    ]
}