// user
const Listuser = () => import('@/views/user_management/index')
const Detailuser = () => import('@/views/user_management/detail')

export default {
    path: 'user',
    redirect: '/user',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'User'
    },
    children: [{
            path: '/',
            name: 'user',
            component: Listuser,
            meta: {
                auth: true,
                label: 'User',
                permission: {
                    link: "user",
                    name: "User Management"
                }
            }
        },
        {
            path: ':id',
            name: 'detailuser',
            component: Detailuser,
            meta: {
                auth: true,
                label: 'Detail User',
                permission: {
                    link: "user",
                    name: "User Management"
                },
            }
        },
    ]
}