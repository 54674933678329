// admin
const Listadmin = () => import('@/views/admin_management/admin/index')
const Formadmin = () => import('@/views/admin_management/admin/form')

export default {
    path: 'admin',
    redirect: '/admin',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'admin'
    },
    children: [{
            path: '/',
            name: 'admin',
            component: Listadmin,
            meta: {
                auth: true,
                label: 'Admin',
                permission: {
                    link: "admin",
                    name: "Admin"
                }
            }
        },
        {
            path: 'add',
            name: 'addadmin',
            component: Formadmin,
            meta: {
                auth: true,
                label: 'Add Admin',
                permission: {
                    link: "admin",
                    name: "Admin",
                }
            }
        },
        {
            path: 'edit/:id',
            name: 'editadmin',
            component: Formadmin,
            meta: {
                auth: true,
                label: 'Edit Admin',
                permission: {
                    link: "admin",
                    name: "Admin"
                },
            }
        },
    ]
}