// role
const Listrole = () => import('@/views/admin_management/role/index')
const Formrole = () => import('@/views/admin_management/role/form')

export default {
    path: 'role',
    redirect: '/role',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'Role'
    },
    children: [{
            path: '/',
            name: 'role',
            component: Listrole,
            meta: {
                auth: true,
                label: 'Role',
                permission: {
                    link: "role",
                    name: "Role",
                }
            }
        },
        {
            path: 'add',
            name: 'addrole',
            component: Formrole,
            meta: {
                auth: true,
                label: 'Add Role',
                permission: {
                    link: "role",
                    name: "Role",
                }
            }
        },
        {
            path: 'edit/:id',
            name: 'editrole',
            component: Formrole,
            meta: {
                auth: true,
                label: 'Edit Role',
                permission: {
                    link: "role",
                    name: "Role",
                },
            }
        },
    ]
}