export default {
    user: 'user',
    role: 'role',
    news: 'news',
    news_category: 'news_category',
    newsletter: 'newsletter',
    epaper: 'epaper',
    package: 'package',
    advertisement: 'advertisement',
    page: 'page',
    media: 'media',
    menu: 'menu',
    profile: 'profile',
    menu_user: 'profile/menu',
    login: 'login',
    forgot_password: 'forgot_password',
    reset_password: 'reset_password',
    setting_position: 'setting_position',
    content: 'content',
    dashboard: 'dashboard',
    notification: 'notification',
    market_data: 'market_data',
    transaction: 'transaction'
}