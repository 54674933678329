// membership
const Listmembership = () => import('@/views/membership/membership')
const Detailmembership = () => import('@/views/user_management/detail')

export default {
    path: 'membership',
    redirect: '/membership',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'Membership'
    },
    children: [{
            path: '/',
            name: 'membership',
            component: Listmembership,
            meta: {
                auth: true,
                label: 'Membership',
                permission: {
                    link: "membership",
                    name: "Membership",
                }
            }
        },{
            path: ':id',
            name: 'detailmembership',
            component: Detailmembership,
            meta: {
                auth: true,
                label: 'Detail User',
                permission: {
                    link: "membership",
                    name: "Membership",
                }
            }
        },
    ]
}