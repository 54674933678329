<template>
  <main>
    <router-view :key="$route.path"></router-view>
    <spinner />
  </main>
</template>

<script>
import spinner from './components/spinner.vue'
export default {
  components: { spinner },
  name: 'app'
}
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  // @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .sidebar .nav-dropdown-items .nav-item {
    padding: 0 0 0 15px!important;
  }
  .ql-container .ql-editor {
    min-height: 260px;
    width: 100%;
    overflow-y: auto;
  }

  table tbody span.badge {
    text-transform: capitalize;
  }
</style>
