// advertisement
const Listadvertisement = () => import('@/views/advertisement')
const Formadvertisement = () => import('@/views/advertisement/form')
const Detailadvertisement = () => import('@/views/advertisement/detail')

export default {
    path: 'advertisement',
    redirect: '/advertisement',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'Advertisement'
    },
    children: [{
            path: '/',
            name: 'advertisement',
            component: Listadvertisement,
            meta: {
                auth: true,
                label: 'Advertisement',
                permission: {
                    link: "advertisement",
                    name: "Advertisement",
                }
            }
        },
        {
            path: 'add',
            name: 'addadvertisement',
            component: Formadvertisement,
            meta: {
                auth: true,
                label: 'Add Advertisement',
                permission: {
                    link: "advertisement",
                    name: "Advertisement",
                }
            }
        },
        {
            path: 'edit/:id',
            name: 'editadvertisement',
            component: Formadvertisement,
            meta: {
                auth: true,
                label: 'Edit Advertisement',
                permission: {
                    link: "advertisement",
                    name: "Advertisement",
                },
            }
        },
        {
            path: ':id',
            name: 'detailadvertisement',
            component: Detailadvertisement,
            meta: {
                auth: true,
                label: 'Detail Advertisement',
                permission: {
                    link: "advertisement",
                    name: "Advertisement",
                },
            }
        },
    ]
}