// ROUTE FOR VISITOR ONLY

const Listarticle = () => import('@/views/article_visitor')
const Formarticle = () => import('@/views/article_visitor/form')
const Detailarticle = () => import('@/views/article_visitor/detail')

export default {
    path: 'article',
    redirect: '/article',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'Article'
    },
    children: [{
            path: '/',
            name: 'article',
            component: Listarticle,
            meta: {
                auth: true,
                label: 'My Article',
                permission: {
                    link: "article",
                    name: "Article",
                }
            }
        },
        {
            path: 'add',
            name: 'addarticle',
            component: Formarticle,
            meta: {
                auth: true,
                label: 'Add Article',
                permission: {
                    link: "article",
                    name: "Article",
                }
            }
        },
        {
            path: 'edit/:id',
            name: 'editarticle',
            component: Formarticle,
            meta: {
                auth: true,
                label: 'Edit Article',
                permission: {
                    link: "article",
                    name: "Article",
                },
            }
        },
        {
            path: ':id',
            name: 'detailarticle',
            component: Detailarticle,
            meta: {
                auth: true,
                label: 'Detail Article',
                permission: {
                    link: "article",
                    name: "Article",
                },
            }
        },
    ]
}