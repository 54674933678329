// media
const ListMedia = () => import('@/views/media/index')

export default {
    path: 'media',
    redirect: '/media',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'Media Storage'
    },
    children: [{
            path: '/',
            name: 'media',
            component: ListMedia,
            meta: {
                auth: true,
                label: 'Media Storage',
                permission: {
                    link: "media",
                    name: "Media",
                }
            }
        },
    ]
}