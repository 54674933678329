// news
const Listnews = () => import('@/views/news_management/news/index')
const Formnews = () => import('@/views/news_management/news/form')
const Detailnews = () => import('@/views/news_management/news/detail')

export default {
    path: 'news',
    redirect: '/news',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'News'
    },
    children: [{
            path: '/',
            name: 'news',
            component: Listnews,
            meta: {
                auth: true,
                label: 'News',
                permission: {
                    link: "news",
                    name: "News",
                }
            }
        },
        {
            path: ':id',
            name: 'detailnews',
            component: Detailnews,
            meta: {
                auth: true,
                label: 'Detail News',
                permission: {
                    link: "news",
                    name: "News",
                }
            }
        },
        {
            path: 'article/add',
            name: 'addarticlenews',
            component: Formnews,
            meta: {
                auth: true,
                type: 'article',
                label: 'Add Article News',
                permission: {
                    link: "news",
                    name: "News",
                }
            }
        },
        {
            path: 'photo/add',
            name: 'addphotonews',
            component: Formnews,
            meta: {
                auth: true,
                type: 'photo',
                label: 'Add Photo News',
                permission: {
                    link: "news",
                    name: "News",
                }
            }
        },
        {
            path: 'video/add',
            name: 'addvideonews',
            component: Formnews,
            meta: {
                auth: true,
                type: 'video',
                label: 'Add Video News',
                permission: {
                    link: "news",
                    name: "News",
                }
            }
        },
        {
            path: 'edit/article/:id',
            name: 'editarticlenews',
            component: Formnews,
            meta: {
                auth: true,
                type: 'article',
                label: 'Edit News',
                permission: {
                    link: "news",
                    name: "News",
                },
            }
        },
        {
            path: 'edit/photo/:id',
            name: 'editphotonews',
            component: Formnews,
            meta: {
                auth: true,
                type: 'photo',
                label: 'Edit News',
                permission: {
                    link: "news",
                    name: "News",
                },
            }
        },
        {
            path: 'edit/video/:id',
            name: 'editvideonews',
            component: Formnews,
            meta: {
                auth: true,
                type: 'video',
                label: 'Edit News',
                permission: {
                    link: "news",
                    name: "News",
                },
            }
        },
    ]
}