// package
const Listpackage = () => import('@/views/membership/package/index')
const Formpackage = () => import('@/views/membership/package/form')

export default {
    path: 'package',
    redirect: '/package',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'Package'
    },
    children: [{
            path: '/',
            name: 'package',
            component: Listpackage,
            meta: {
                auth: true,
                label: 'Package',
                permission: {
                    link: "package",
                    name: "Package",
                }
            }
        },
        {
            path: 'add',
            name: 'addpackage',
            component: Formpackage,
            meta: {
                auth: true,
                label: 'Add Package',
                permission: {
                    link: "package",
                    name: "Package",
                }
            }
        },
        {
            path: 'edit/:id',
            name: 'editpackage',
            component: Formpackage,
            meta: {
                auth: true,
                label: 'Edit Package',
                permission: {
                    link: "package",
                    name: "Package",
                },
            }
        },
    ]
}