import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import api from '@/utils/api.js';
import store from '@/store/store.js';
import role from '@/routers/admin_management/role';
import admin from '@/routers/admin_management/admin';
import news from '@/routers/news_management/news';
import category from '@/routers/news_management/category';
import epaper from '@/routers/news_management/epaper';
import user from '@/routers/user_management';
import page from '@/routers/page_management';
import newsletter from '@/routers/newsletter';
import membership from '@/routers/membership/membership';
import contributor from '@/routers/contributor/contributor';
import article from '@/routers/contributor/article';
import advertisement from '@/routers/advertisement';
import membership_package from '@/routers/membership/package';
import media from '@/routers/media';
import articleVisitor from '@/routers/article_visitor';

// Containers
const DefaultContainer = () => import ('@/containers/DefaultContainer')

//page
const Login = () =>
    import ('@/views/auth/login')
const ForgotPassword = () =>
    import ('@/views/auth/forgot_password')
const ResetPassword = () =>
    import ('@/views/auth/reset_password')
const Page404 = () =>
    import ('@/views/pages/page_404')
const Page500 = () =>
    import ('@/views/pages/page_500')
const Dashboard = () =>
    import ('@/views/pages/dashboard')
const ChangeProfile = () =>
    import ('@/views/auth/change_profile')
const ChangePassword = () =>
    import ('@/views/auth/change_password')
const Setting = () =>
    import ('@/views/pages/setting')

Vue.use(Router)

const router = new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        {
            path: '/',
            redirect: '/dashboard',
            name: 'home',
            component: DefaultContainer,
            meta: {
                label: "Home",
            },
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta: {
                        auth: true,
                        permission: {
                            link: "dashboard",
                            name: "dashboard",
                        }
                    }
                }, {
                    path: 'change-profile',
                    name: 'Change Profile',
                    component: ChangeProfile,
                    meta: {
                        auth: true,
                        label: 'Change Profile',
                    }
                }, {
                    path: 'change-password',
                    name: 'Change Password',
                    component: ChangePassword,
                    meta: {
                        auth: true,
                        label: 'Change Password',
                    }
                }, {
                    path: 'setting',
                    name: 'Setting',
                    component: Setting,
                    meta: {
                        auth: true,
                        label: 'Setting',
                        permission: {
                            name: "setting",
                        }
                    }
                },
                role,
                admin,
                media,
                user,
                news,
                category,
                epaper,
                newsletter,
                membership,
                membership_package,
                page,
                advertisement,
                contributor,
                article,


                // Route for articleVisitor
                articleVisitor
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                guest: true
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot password',
            component: ForgotPassword,
            meta: {
                guest: true
            }
        },
        {
            path: '/resetpassword',
            name: 'reset password',
            component: ResetPassword,
            meta: {
                guest: true
            }
        },
        {
            path: '/500',
            name: 'page500',
            component: Page500,
        },
        {
            path: '*',
            name: 'page404',
            component: Page404,
        }
    ]
})

const getMenu = () => {
    let nav = []
    return axios.get(api.menu_user)
    .then(resp => {
        const menu = resp.data.data;
        menu.map(val => {
            if (!val.children.length) {
            delete val.children;
            }
            nav.push(val);
        });
        store.dispatch('setNav', nav)
        return nav
    }).catch(e => {
        console.log(e)
    })
}

const checkUserPermission = async (to, next) => {
    // get user object menu
    let userMenu = store.state.nav
    if(userMenu.length === 0) {
        userMenu = await getMenu()
    } 

    // assign new variable to get menu name
    let menus = []
    userMenu.forEach(menu => {
        if(menu.children) {
            let children = menu.children
            children.forEach(child => {
                menus.push(child.name.toLowerCase())
            })
        } else {
            menus.push(menu.name.toLowerCase())
        }
    })

    if (to.matched.some(record => record.meta.permission)) {
        const {name} = to.meta.permission
        if(name) {
            // menus.includes(name.toLowerCase()) ? next() : next('/404')
            if(menus.includes(name.toLowerCase())) {
                next()
            } else {
                if(name === 'dashboard') {
                    if(userMenu[0].children) {
                        next(`${userMenu[0].children[0].url}`)
                    } else {
                        next(`${userMenu[0].url}`)
                    }
                } else {
                    next('/404')
                }
            }
        } else {
            next()
        }
    } else {
        next()
    }
}

router.beforeEach((to, from, next) => {
    /* auth middleware */
    if (to.matched.some(record => record.meta.auth)) {
        if (!localStorage.getItem('token')) {
            next('/login');
        } else {
            checkUserPermission(to, next)
        }
    } else if(to.matched.some(record => record.meta.guest)){
        if (localStorage.getItem('token')) {
            next('/dashboard');
        } else {
            next()
        }
    } else {
        next();
    }
});

export default router