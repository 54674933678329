import 'es6-promise/auto'
import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex)

//=======vuex store start===========
const store = new Vuex.Store({
    state: {
        user: {},
        nav: [],
        spinner : false,
    },
    mutations,
    actions,
    getters,
});
//=======vuex store end===========
export default store;
