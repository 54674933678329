// contributor
const Listcontributor = () => import('@/views/contributor/contributor')
const Detailcontributor = () => import('@/views/user_management/detail')

export default {
    path: 'contributor',
    redirect: '/contributor',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'Contributor'
    },
    children: [{
            path: '/',
            name: 'contributor',
            component: Listcontributor,
            meta: {
                auth: true,
                label: 'Contributor',
                permission: {
                    link: "contributor",
                    name: "Contributor",
                }
            }
        },{
            path: ':id',
            name: 'detailcontributor',
            component: Detailcontributor,
            meta: {
                auth: true,
                label: 'Detail Contributor',
                permission: {
                    link: "contributor",
                    name: "Contributor",
                }
            }
        },
    ]
}