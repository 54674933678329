// page
const Listpage = () => import('@/views/page_management')
const Formpage = () => import('@/views/page_management/form')
const Detailpage = () => import('@/views/page_management/detail')

export default {
    path: 'page',
    redirect: '/page',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'Page'
    },
    children: [{
            path: '/',
            name: 'page',
            component: Listpage,
            meta: {
                auth: true,
                label: 'Page',
                permission: {
                    link: "page",
                    name: "Page Management"
                }
            }
        },
        {
            path: 'add',
            name: 'addpage',
            component: Formpage,
            meta: {
                auth: true,
                label: 'Add Page',
                permission: {
                    link: "page",
                    name: "Page Management"
                }
            }
        },
        {
            path: 'edit/:id',
            name: 'editpage',
            component: Formpage,
            meta: {
                auth: true,
                label: 'Edit Page',
                permission: {
                    link: "page",
                    name: "Page Management"
                },
            }
        },
        {
            path: ':id',
            name: 'detailpage',
            component: Detailpage,
            meta: {
                auth: true,
                label: 'Detail Page',
                permission: {
                    link: "page",
                    name: "Page Management"
                },
            }
        },
    ]
}