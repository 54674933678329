// e-paper
const Listepaper = () => import('@/views/news_management/epaper')
const Formepaper = () => import('@/views/news_management/epaper/form')
const Detailepaper = () => import('@/views/news_management/epaper/detail')

export default {
    path: 'epaper',
    redirect: '/epaper',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'E-paper'
    },
    children: [
        {
            path: '/',
            name: 'epaper',
            component: Listepaper,
            meta: {
                auth: true,
                label: 'E-paper',
                permission: {
                    link: "epaper",
                    name: "E-Paper",
                }
            }
        },
        {
            path: 'add',
            name: 'addepaper',
            component: Formepaper,
            meta: {
                auth: true,
                label: 'Add E-paper',
                permission: {
                    link: "epaper",
                    name: "E-Paper",
                }
            }
        },
        {
            path: 'edit/:id',
            name: 'editepaper',
            component: Formepaper,
            meta: {
                auth: true,
                label: 'Edit E-paper',
                permission: {
                    link: "epaper",
                    name: "E-Paper",
                },
            }
        },
        {
            path: ':id',
            name: 'detailepaper',
            component: Detailepaper,
            meta: {
                auth: true,
                label: 'Detail E-paper',
                permission: {
                    link: "epaper",
                    name: "E-Paper",
                },
            }
        },
    ]
}