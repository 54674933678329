// newsletter
const Listnewsletter = () => import('@/views/newsletter')
const Formnewsletter = () => import('@/views/newsletter/form')
const Detailnewsletter = () => import('@/views/newsletter/detail')

export default {
    path: 'newsletter',
    redirect: '/newsletter',
    component: {
        render(c) { return c('router-view') }
    },
    meta: {
        label: 'Newsletter'
    },
    children: [{
            path: '/',
            name: 'newsletter',
            component: Listnewsletter,
            meta: {
                auth: true,
                label: 'Newsletter',
                permission: {
                    link: "newsletter",
                    name: "Newsletter",
                }
            }
        },
        {
            path: 'add',
            name: 'addnewsletter',
            component: Formnewsletter,
            meta: {
                auth: true,
                label: 'Add Newsletter',
                permission: {
                    link: "newsletter",
                    name: "Newsletter",
                }
            }
        },
        {
            path: 'edit/:id',
            name: 'editnewsletter',
            component: Formnewsletter,
            meta: {
                auth: true,
                label: 'Edit Newsletter',
                permission: {
                    link: "newsletter",
                    name: "Newsletter",
                },
            }
        },
        {
            path: ':id',
            name: 'detailnewsletter',
            component: Detailnewsletter,
            meta: {
                auth: true,
                label: 'Detail Newsletter',
                permission: {
                    link: "newsletter",
                    name: "Newsletter",
                },
            }
        },
    ]
}